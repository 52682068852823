import React, { useContext, memo, useEffect } from "react"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import AuthContext from "../../../context/AuthContext"
import { isInStoreFrontend } from "../../../services/auth"

import Grid from "../../layout/grid"
import SvgIcon from "../../atoms/icons/svg-icon"
import Lock from "../../atoms/icons/files/lock.jsx"
import LogoVisa from "../../atoms/icons/files/logo-visa.jsx"
import LogoMastercard from "../../atoms/icons/files/logo-mastercard.jsx"
import Phone from "../../atoms/icons/files/phone.jsx"
import WhatsApp from "../../atoms/icons/files/whatsapp.jsx"
import UserCircle from "../../atoms/icons/files/user-circle.jsx"
import Login from "../../atoms/icons/files/login.jsx"
import PenSquare from "../../atoms/icons/files/pen-square.jsx"
import MenuLoader from "../../organisms/menuLoader"
import { BtnPill } from "../../atoms/buttons"

import ButtonGrid from "./buttonGrid"
import BranchSelectButton from "../../../components/BranchSelect/BranchSelectButton"

const headerSkinny = () => {
  const { loggedInState, performLogout } = useContext(AuthContext)
  const isInStoreFrontendState = isInStoreFrontend()

  const data = useStaticQuery(graphql`
    {
      payflexImage: file(relativePath: { eq: "payflex-slim.png" }) {
        childImageSharp {
          gatsbyImageData(height: 20, layout: FIXED)
        }
      }
      localWpGraphQlTmwGeneralSettings {
        tmw_settings {
          contactDetails {
            genericPhone
          }
          promotion {
            promoText
            buttonText
            link
          }
        }
      }
    }
  `)

  const { genericPhone } =
    data.localWpGraphQlTmwGeneralSettings.tmw_settings.contactDetails
  const promotion = data.localWpGraphQlTmwGeneralSettings.tmw_settings.promotion
  const payflexImage = data.payflexImage.childImageSharp.gatsbyImageData

  return (
    <HeaderSkinnyWrapper isInStoreFrontendState={isInStoreFrontendState}>
      <Grid cols="3" narrowGutter="15px">
        <Start>
          <p className="small">{promotion.promoText}</p>
          <BtnPill
            hue="ruby"
            shade="080"
            size="sm"
            mode="solid"
            to={`/${promotion.link}`}
          >
            {promotion.buttonText}
          </BtnPill>
          <BtnPill
            hue="ocean"
            shade="060"
            size="sm"
            mode="solid"
            to={`/hospitality-beds-and-mattresses/`}
          >
            Bulk Deals
          </BtnPill>
        </Start>
        <ContactBoxGrid>
          {/*<Secure>*/}
          {/*  <SvgIcon SvgComponent={Lock} hue="tusk" shade="080" size="md" />*/}
          {/*  <div className="txt-label">Secure</div>*/}
          {/*  <SvgIcon SvgComponent={LogoVisa} hue="tusk" shade="080" size="md" />*/}
          {/*  <SvgIcon*/}
          {/*    SvgComponent={LogoMastercard}*/}
          {/*    hue="tusk"*/}
          {/*    shade="080"*/}
          {/*    size="md"*/}
          {/*  />*/}
          {/*  <GatsbyImage*/}
          {/*    // style={{*/}
          {/*    //   minWidth: "77px",*/}
          {/*    //   padding: "0 2px",*/}
          {/*    // }}*/}
          {/*    alt="payflex-logo"*/}
          {/*    width="77px"*/}
          {/*    loading="eager"*/}
          {/*    placeholder="none"*/}
          {/*    image={payflexImage}*/}
          {/*  />*/}
          {/*</Secure>*/}
          <ButtonExternal href={`tel:${genericPhone.replace(/ /gi, "")}`}>
            <SvgIcon SvgComponent={Phone} hue="sky" shade="000" />
            {genericPhone}
          </ButtonExternal>
          <ButtonExternal href={`https://wa.me/+27828649320`} target="_blank">
            <SvgIcon SvgComponent={WhatsApp} hue="sky" shade="000" />
            WhatsApp
          </ButtonExternal>
        </ContactBoxGrid>
        <End>
          <BranchSelectButton />
          {loggedInState === true && (
            <>
              <ButtonGrid to="/my-account/">
                <SvgIcon SvgComponent={UserCircle} hue="sky" shade="000" />
                My Account
              </ButtonGrid>
              <ButtonGrid onClick={() => performLogout("/")} as="div">
                <SvgIcon SvgComponent={Login} hue="sky" shade="000" />
                Logout
              </ButtonGrid>
            </>
          )}
          {loggedInState === null && <MenuLoader />}
          {loggedInState === false && (
            <>
              <ButtonGrid to="/login/">
                <SvgIcon SvgComponent={Login} hue="sky" shade="000" />
                Login
              </ButtonGrid>
              <ButtonGrid to="/register/">
                <SvgIcon SvgComponent={PenSquare} hue="sky" shade="000" />
                Register
              </ButtonGrid>
            </>
          )}
        </End>
      </Grid>
    </HeaderSkinnyWrapper>
  )
}

export default memo(headerSkinny)

const HeaderSkinnyWrapper = styled.div`
  background: ${(props) =>
    props.isInStoreFrontendState
      ? props.theme.colors.ocean["080"]
      : props.theme.colors.midnight["100"]};
  color: ${({ theme }) => theme.colors.white["000"]};
  padding: 5px 0;
  .grid {
    grid-gap: 20px;
    grid-template-columns: auto auto auto;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .grid {
      // grid-template-columns: 1fr auto auto;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`
const Start = styled.div`
  display: grid;
  // grid-template-columns: 1fr 120px;
  // grid-template-columns: 1fr 165px;
  grid-template-columns: 164px 99px 100px;
  //grid-template-columns: 164px 159px 100px;
  grid-gap: 15px;
  justify-self: flex-start;
  align-items: center;
  p {
    justify-self: flex-end;
  }
  @media (max-width: 1050px) {
    // grid-template-columns: 120px;
    // grid-template-columns: 165px;
    grid-template-columns: 99px 100px;
    //grid-template-columns: 159px 100px;
    p {
      display: none;
    }
  }
`
const End = styled.div`
  justify-self: flex-end;
  display: flex;
`
const ContactBoxGrid = styled.div`
  // display: grid;
  justify-self: center;
  // grid-template-columns: 1fr 1fr;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  p {
    color: ${({ theme }) => theme.colors.tusk["020"]};
    display: grid;
    grid-template-columns: 28px 1fr;
    align-items: center;
  }
`
const ButtonExternal = styled.a`
  display: grid;
  grid-template-columns: 28px 100px;
  margin: 0 5px;
  align-items: center;
  color: ${({ theme }) => theme.colors.tusk["020"]};
  transition: all 200ms ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.white["000"]};
    transform: translateY(-1px);
  }
`

const Secure = styled.div`
  display: flex;
  align-items: center;
`
