import React, { useContext, memo } from "react"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"
import { path } from "../../../lib/util"

import AuthContext from "../../../context/AuthContext"
import plus from "../../../images/icons/light-blue-plus.svg"
import minus from "../../../images/icons/light-blue-minus.svg"
import location from "../../../images/icons/light-blue-location.svg"
import phone from "../../../images/icons/light-blue-phone.svg"
import profile from "../../../images/icons/light-blue-profile.svg"
import search from "../../../images/icons/light-blue-search.svg"

import LinkWrapper from "../../../utils/linkWrapper"
import MobileHeading from "./MobileHeading"

const Space = styled.div`
  height: 300px;
`

const MobileMenu = () => {
  const { loggedInState, performLogout } = useContext(AuthContext)
  const [open, setOpen] = React.useState(false)
  const data = useStaticQuery(QUERY)
  const items = path(["menu", "nodes", 0, "menuStructure"], data)

  // Custom extra menu items not on desktop
  const itemsExtended = [
    { label: "Home", url: "/" },
    // {label: "Black Friday Deals", url: "/black-friday-bed-sale/", class: "highlight black-friday", },
    { label: "Bed Specials", url: "/bed-specials/", class: "highlight" },
    ...items,
    ...(!loggedInState
      ? []
      : [{ label: "Logout", onClick: () => performLogout() }]),
  ]

  return (
    <Wrap className="mobile">
      <MobileHeading open={open} setOpen={setOpen} />
      <Drawer drawerOpen={open}>
        {itemsExtended.map((node, i) => (
          <Expander item={node} key={i} level={1} />
        ))}
        <Space />
        <Bottom>
          <TextIcon as={LinkWrapper} to="/bed-finder/">
            <img src={search} />
            Search
          </TextIcon>
          <TextIcon
            as={LinkWrapper}
            to={loggedInState ? "/my-account/" : "/login/"}
          >
            <img src={profile} />
            {loggedInState ? "My Account" : "Login / Register"}
          </TextIcon>
          <TextIcon as={LinkWrapper} to="/contact-us/">
            <img src={location} />
            Store Finder
          </TextIcon>
          <TextIcon
            as="a"
            href={
              "tel:" +
              data.localWpGraphQlTmwGeneralSettings.tmw_settings.contactDetails
                .genericPhone
            }
          >
            <img src={phone} />
            {
              data.localWpGraphQlTmwGeneralSettings.tmw_settings.contactDetails
                .genericPhone
            }
          </TextIcon>
        </Bottom>
      </Drawer>
    </Wrap>
  )
}

const TextIcon = styled.div`
  display: flex;
  font-size: 16px;
  padding: 10px;
  color: white;
  text-decoration: none;

  img {
    margin-right: 15px;
    width: 20px;
  }
`

const Expander = ({ item, level }) => {
  const [visible, setVisible] = React.useState(false)
  const hasChildren =
    item.childItems && item.childItems && item.childItems.length > 0

  return (
    <Item className={"level-" + level}>
      <Label
        as={!hasChildren ? LinkWrapper : "span"}
        onClick={() => {
          setVisible((v) => !v)
          item.onClick?.()
        }}
        to={item.url}
        className={item.class}
      >
        {item.label}
        {hasChildren && <img src={visible ? minus : plus} />}
      </Label>
      {hasChildren && (
        <Content contentOpen={visible} withParent={false}>
          {item.childItems.map((node, i) => (
            <Expander key={i} item={node} level={level + 1} />
          ))}
        </Content>
      )}
    </Item>
  )
}

const Label = styled.span`
  color: white;
  -webkit-tap-highlight-color: transparent;
  &.black-friday {
    color: #ed1c24;
  }
`

const Bottom = styled.div`
  background-color: #223247;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: 0.3s all ease-in-out;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`

const Content = styled.div`
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  max-height: ${({ contentOpen }) => (contentOpen ? "6000px" : "0")};
  transition: ${({ contentOpen }) =>
    contentOpen ? "all 150ms ease-in" : "all 150ms ease-out"};
`

const Item = styled.div`
  -webkit-tap-highlight-color: transparent;
  background: #162232;
  color: white;
  font-size: 18px;
  border-bottom: 1px solid #1e2f46;
  cursor: pointer;
  span,
  a {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    img {
      width: 20px;
      height: 20px;
    }
  }
  &.level-2 {
    background: #101925;
    span,
    a {
      padding-left: 40px;
    }
  }
  &.level-3 {
    background: #090e15;
    span,
    a {
      padding-left: 60px;
    }
  }
`

const Wrap = styled.div`
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
`

const Drawer = styled.div`
  background: #162232;
  height: 100vh;
  position: relative;
  ${({ drawerOpen }) =>
    drawerOpen ? "overflow-y: scroll;" : "transform: translateX(-100%);"}
  transition: transform 150ms ease-out;
`

export default memo(MobileMenu)

const QUERY = graphql`
  {
    localWpGraphQlTmwGeneralSettings {
      tmw_settings {
        contactDetails {
          genericPhone
        }
      }
    }
    #######################
    menu: allDataJson {
      nodes {
        menuStructure {
          childItems {
            childItems {
              label
              url
            }
            label
            url
          }
          label
          url
        }
      }
    }
  }
`
